<template>
  <v-navigation-drawer :width="navWidth" app v-model="localNavDrawer" mobile-breakpoint="960">
    <template #prepend>
      <div class="ma-4 mr-2 d-flex algin-center justify-space-between">
        <img
          :src="$store.state.userSettings.darkMode ? paradiseInverseLogo : paradiseLogo"
          height="19"
          alt="Paradise Logo"
        />
        <toggle-nav-btn v-if="navDrawer" @toggleMenu="toggleMenu"></toggle-nav-btn>
      </div>
    </template>

    <navLinks></navLinks>

    <template #append>
      <div class="pa-4 mb-2">
        <img
          :src="$store.state.userSettings.darkMode ? clientInverseLogo : clientLogo"
          width="100%"
          alt="Cardioguard"
        />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {
    NavLinks: () => import("./NavLinks.vue"),
    ToggleNavBtn: () => import("./ToggleNavBtn"),
  },
  props: {
    navDrawer: {
      type: Boolean,
    },
  },
  data: () => ({
    navWidth: 200,
    clientLogo: require("../../../assets/images/client/logo.svg"),
    clientInverseLogo: require("../../../assets/images/client/logo_inverse.svg"),
    paradiseLogo: require("../../../assets/images/paradise_logo.svg"),
    paradiseInverseLogo: require("../../../assets/images/paradise_logo_inverse.svg"),
  }),
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu", !this.navDrawer);
    },
  },
  computed: {
    localNavDrawer: {
      get() {
        return this.navDrawer;
      },
      set(value) {
        this.$emit("toggleMenu", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
